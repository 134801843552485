import React from 'react'
import Props from 'prop-types'
import {
  Container,
  BlogPreview,
  BlogPreviewContent,
  BlogPreviewImage,
  BlogPreviewMeta,
  BlogPreviewTitle,
} from './styled'
import dayjs from 'dayjs'

const BlogList = ({ posts }) => (
  <Container>
    {posts.map(post => (
      <BlogPreview key={post.slug} href={`/blog/${post.slug}`}>
        <BlogPreviewImage src={post.coverImage} alt={post.title} />
        <BlogPreviewContent>
          <BlogPreviewTitle>{post.title}</BlogPreviewTitle>
          <BlogPreviewMeta>
            <span>{post.authorName}</span>
            <span>{dayjs(post.date, 'YYYY-MM-DD').format('MMMM D, YYYY')}</span>
          </BlogPreviewMeta>
        </BlogPreviewContent>
      </BlogPreview>
    ))}
  </Container>
)

BlogList.defaultProps = {
  posts: [],
}

BlogList.propTypes = {
  posts: Props.array,
}

export default BlogList
