import styled from 'styled-components'
import { theme } from 'common/styles'

const border = `1px solid ${theme.colors.lightGrey}`

export const Container = styled.section`
  display: flex;
  gap: 1.7rem;
  flex-wrap: wrap;
`

export const BlogPreview = styled.a`
  display: flex;
  flex-direction: column;
  width: 33rem;
  border: ${border};
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-0.5rem);
  }
`

export const BlogPreviewImage = styled.img`
  width: 100%;
  height: 25rem;
  object-fit: cover;
`

export const BlogPreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  flex-grow: 1;
  padding: 2rem;
`

export const BlogPreviewTitle = styled.h2`
  font-size: 2rem;
  line-height: 1.4;
  font-weight: bold;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

export const BlogPreviewMeta = styled.div`
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`
